<template>
  <div>
    <v-dialog v-model="SubColLookupDialog"  width="400">
      <v-card width="100%">
        <v-card-title>
          Dialog<v-spacer></v-spacer><v-icon color="red" @click="DeactivateSubColLookupDialog()">mdi-close</v-icon>
        </v-card-title>
        <v-card-text>
          <v-list>
            <v-list-item v-for="item in SelectedSubcollectionArray" :key="item.itemObjKey">
              {{item.Item_Name}}
            </v-list-item>
          </v-list>
          <v-window  v-model="subcollectstep">
            <v-window-item v-for="(item,i) in SelectedSubcollectionArray" :key="item.itemObjKey" :value="i-1+2">
              
              <v-layout class="justify-center">
                  <v-card width="400">
                    <v-card-title>
                      <span class="headline"><strong>Step {{i-1+2}} of {{SelectedSubcollectionArray.length}}</strong> - Link</span>
                    </v-card-title>
                    <v-card-text>
                      <!-- <v-row>
                      <v-col cols="12" sm="8" md="12" lg="12"> -->
                       <FieldsForm :FormFields="item.Fields" :Validating="Validating" :DataTableView="true" @save="save" :DataObject="SelectedSubcollectionArray[i]" :tab="{}" />
                      <!-- </v-col>
                    </v-row>     -->
                      <!-- <v-layout class="mx-3" v-for="field in item.Fields" :key="field.itemObjKey">
                                  <v-select v-if="field.Type === 'Option Set'" :items="field.Options" item-text="Name" :label="field.text" v-model="field.Inputvalue"></v-select>
                                  <v-icon v-if="field.Type === 'Option Set'" v-model="field.Inputvalue">mdi-cogs</v-icon>                            
                                  <v-text-field v-if="field.Type === 'Single Line Text'" :label="field.text" v-model="field.Inputvalue"></v-text-field>
                                  <v-text-field type="number" v-if="field.Type === 'Number Field'" :label="field.text" v-model="field.Inputvalue"></v-text-field>
                                  <v-textarea v-if="field.Type === 'Multiple Lines Text' " :label="field.text" v-model="field.Inputvalue"></v-textarea>
                                  <v-switch v-if="field.Type === 'Boolean' " :label="field.text" v-model="field.Inputvalue"></v-switch>
                                  <v-autocomplete v-if="field.Type === 'Lookup' " :label="field.text" v-model="field.Inputvalue"  :items="field.array" :item-text="field.identifier"></v-autocomplete>
                                  <v-menu v-if="field.Type === 'Date'"
                                  :close-on-content-click="false"
                                  :nudge-right="40"
                                  transition="scale-transition"
                                  offset-y
                                  min-width="200px"
                                  v-model="field.Menu"
                                  >
                                    <template v-slot:activator="{ on }">
                                      <v-text-field
                                        :label="field.text"
                                        prepend-icon="mdi-calendar-month"
                                        readonly
                                        v-on="on"
                                         v-model="field.Inputvalue"
                                      ></v-text-field>
                                      </template>
                                    <v-date-picker v-model="field.Inputvalue" @input="field.Menu = false"></v-date-picker>
                                  </v-menu>
                                   <label :for="field.text">{{field.text}}</label>
                                  <input type="file"  v-if="field.Type === 'Single File Upload'" :id="field.text">
                                  </v-layout>      -->
                    </v-card-text>
                    <v-card-actions>
                      <v-btn
                        :disabled="subcollectstep === 1"
                        text
                        @click="subcollectstep--"
                      >
                        Back
                      </v-btn>
                      <v-spacer></v-spacer>
                      <v-btn
                      v-if="i-1+2 < SelectedSubcollectionArray.length"
                        color="primary"
                        depressed
                        @click="CreateSubCollectionItem(item), subcollectstep++"
                      >
                        Create and Next
                      </v-btn>
                      <v-btn
                      v-if="i-1+2 === SelectedSubcollectionArray.length"
                        color="primary"
                        depressed
                        @click="CreateSubCollectionItem(item),AllFieldsLookupDialog = !AllFieldsLookupDialog"
                      >
                        Create and Finish
                      </v-btn>
                    </v-card-actions>
                  </v-card>
              </v-layout>
            </v-window-item>
          </v-window>
        </v-card-text>
      </v-card>
    </v-dialog>
    <LookupComponentSubCollections @LinkSubCollectionArray="LinkSubCollectionArray" :RelatedObjidentifier="RelatedObjidentifier"
    :SystemEntities="SystemEntities"
         :ParentCollection="ParentCollection" :ParentTitle="ParentTitle" @LinkParent="LinkParent" v-if="SCViewExistingLookupDialog"
         @CancelSelection="CancelSelection" @CancelAddParentDialog="CancelSCAddParentDialog" :LookupArrayHeaders="LookupArrayHeaders"
         :LookupArray="LookupArray" :FieldFilter="FieldFilter" :RelatedObj="RelatedObj" :NewParentAdditional="NewParentAdditional"
         :ParentLookup="ParentLookup" :NewParentPrimary="NewParentPrimary" :ViewExistingLookupDialog="SCViewExistingLookupDialog"
         :SubCollectionLookupArrays="SubCollectionLookupArrays" :AddNewParentLookupDialog="AddNewParentLookupDialog"/>
  </div>
</template>

<script>
import firebase from 'firebase';
import db from '@/main'
import axios from 'axios'
import format from 'date-fns/format'
import LookupComponentSubCollections from '@/components/Database/LookupComponentSubCollection'
import FieldsForm from '@/components/Database/FieldsForm';	
export default {
    props: ['System','SystemEntities','SubColLookupDialogActive','MasterTableObj','Line_Items','RelatedObj','CurrentEntity','TableCollectionRef','RADB','tab','RelatedObjidentifier'],
    components: {LookupComponentSubCollections,FieldsForm},
    data() {
        return {
          Validating: false,
            subcollectstep: 1,
            SelectedSubcollectionArray: [],
            SubColLookupDialog: false,
            SubCollectionItemDialog: false, 
            SCViewExistingLookupDialog: false,
            SCAddNewParentLookupDialog: false,
            AddNewParentLookupDialog: false,
            ViewExistingLookupDialog: false,
            FieldFilter: '',
            ParentTitle: '',
            ParentCollection:  '',
            NewParentPrimary: '',
            ParentLookup: '',
            NewParentAdditional: [],
            SubCollectionLookupArrays: [],
            LookupArray: [],
            LookupArrayHeaders: [],
            OutboundStockRecords: [],
            WarehouseEntries: [],
            ConsumeBulkQuantityArrayCounter: 0,
            
        }
    },
    computed:{
      AppsDataBase(){
            return this.$store.state.AppsDataBase
        },
      UsersArray(){
            return this.$store.state.UsersArray
        },
      WarehousesArray(){
        return this.$store.state.WarehousesArray
      },
      RASystemOpsDB(){
      if(this.NeedsRASync){
       return this.RADB.collection('systems').doc(process.env.VUE_APP_RA_SYSTEM_ID).collection('entities')
      }
    },
      RAOperationalDB(){
      if(this.NeedsRASync){
       return this.RADB.collection('systems').doc(process.env.VUE_APP_RA_SYSTEM_ID).collection('entities').doc(this.CurrentEntity.id)
      }
    },
    SystemisRA(){
      return process.env.VUE_APP_RA_SYSTEM_ID === process.env.VUE_APP_RA_PROVIDER_ID
    },
      NeedsRASync(){
        return this.$store.state.NeedsRASync && this.$route.meta && this.$route.meta.TableBuilder || 
        this.$store.state.NeedsRASync && this.SystemConfigorRA && this.tab.Entityid === 'entities'
          
        },
        SystemConfigorRA(){
        return this.$route.meta && this.$route.meta.SystemBuilder || this.$route.meta && this.$route.meta.RecordisSystem
      },
        userLoggedIn () {
        return this.$store.getters.user
        },
        userIsAdmin () {
            return this.$store.state.IsAdmin
        },
        WindowHeight(){
            return window.innerHeight
        },
    },
    watch: {
      SubColLookupDialogActive(value) {
            if (value) {
             this.SubColdialogActivate()              
            }
        },
    },
    created(){
      this.SubColdialogActivate()  
    },
    methods:{
      PerformCalulation(Record,field,IsRollup){
            console.log('PerformCalulation',Record,field)
            let result = 0
                let formulas = JSON.parse(JSON.stringify(field.FieldFormulas))
                formulas = formulas.map(formula => {                
                    if(formula.input === 'percentage field' && formula.fieldName || formula.input === 'field' && formula.fieldName){
                        if(Record[formula.fieldName.Name]){
                            formula.integer = Record[formula.fieldName.Name]
                            if(formula.input === 'percentage field' && formula.fieldName){
                                formula.integer = Record[formula.fieldName.Name]
                                formula.integer = formula.integer*(formula.integer/100)
                            }
                            else if(formula.input === 'field' && formula.fieldName){
                                formula.integer = Record[formula.fieldName.Name]
                            } 
                        }
                    }
                    return formula
                })
                formulas.map((formula,i) => {
                    if(i === 0){
                        result = formula.integer
                    }
                    else if(formulas[i-1].operation === '-'){
                        result = result-formula.integer
                    }
                    else if(formulas[i-1].operation === '+'){
                        result = result+formula.integer
                    }
                    else if(formulas[i-1].operation === '*'){
                        result = result*formula.integer
                    }
                    else if(formulas[i-1].operation === '/'){
                        result = result/formula.integer
                    }
                    else if(formulas[i-1].operation === '='){
                        result = result
                    }
                })
                // if(IsRollup)
            this.$emit('UpdateRecordCalculation',this.Record,field,result)
            return result
        },
      ItemPassSearch(item){
        let pass = false
        let searchfields = this.CurrentEntity.AllFields.filter(field => {
          return field.IsSearch
        })
        //console.log('searchfields',searchfields)
        if(item[this.CurrentEntity.Primary_Field_Name] && item[this.CurrentEntity.Primary_Field_Name].toLowerCase().includes(this.search.toLowerCase())){
          pass = true
        }
        else{
          searchfields.map(field => {
              if(field.Type === 'Single Line Text' || field.Type === 'Multiple Lines Text'){
                if(item[field.Name] && item[field.Name].toLowerCase().includes(this.search.toLowerCase())){
                  pass = true
                }
              }
              else if(field.Type === 'Option Set'){
                if(item[field.Name] && item[field.Name].Name.toLowerCase().includes(this.search.toLowerCase())){
                  pass = true
                }
              }
              else if(field.Type === 'Lookup'){
                if(item[field.Name] && item[field.Name][field.LookupFieldName].toLowerCase().includes(this.search.toLowerCase())){
                  pass = true
                }
              }
          })
        }
        return pass
        
      },
      CancelSelection(){
        this.SCViewExistingLookupDialog = false
      this.AddNewParentLookupDialog = false
      this.ParentTitle = ''
      this.ParentCollection = ''
      this.NewParentPrimary = ''
      this.ParentLookup = ''
      this.NewParentAdditional = []
      this.FieldFilter = ''
      this.LookupArray = []
      this.LookupArrayHeaders = []
      this.SubCollectionLookupArrays = []
      this.$emit('DeactivateSubColLookupDialog')
      },
      CancelSCAddParentDialog(){
      console.log('will cancel')
      this.SCViewExistingLookupDialog = false
      this.AddNewParentLookupDialog = false
      this.ParentTitle = ''
      this.ParentCollection = ''
      this.NewParentPrimary = ''
      this.ParentLookup = ''
      this.NewParentAdditional = []
      this.FieldFilter = ''
      this.LookupArray = []
      this.LookupArrayHeaders = []
      this.SubCollectionLookupArrays = []
      
    },
      CancelAddParentDialog(){
        this.AddNewParentLookupDialog = false
        this.ParentTitle = ''
        this.ParentCollection = ''
        this.NewParentPrimary = ''
        this.ParentLookup = ''
        this.NewParentAdditional = []
        this.FieldFilter = ''
        this.LookupArray = []
        this.LookupArrayHeaders = []
      },
      CreateSubCollectionItem(item){
    console.log(item)  
    let primaryfieldname = ''
    let primaryfielddisplay = ''
    let NewObj = {    
  Modified_By: {Full_Name: this.userLoggedIn.Full_Name,id: this.userLoggedIn.id},
  Modified_Byid: this.userLoggedIn.id,
  Modified_On: new Date(),
 
}
if(this.SystemConfigorRA && item.Entity_Type){
  NewObj.Entity_Type = item.Entity_Type
}
  if(item.LookupObj && item.LinkedFromIdentifier){
    NewObj.LookupObj = item.LookupObj
    NewObj.LinkedFromIdentifier = item.LinkedFromIdentifier
  
}
if(this.SystemConfigorRA && item.RecordPrimaryField || this.$route.meta && this.$route.meta.RecordisSystem && item.Entity_Type){
    NewObj.RecordPrimaryField = item.RecordPrimaryField
    if(item.SingleBuildTabs){
      NewObj.SingleBuildTabs = item.SingleBuildTabs
    }
    else{
      NewObj.SingleBuildTabs = []
    }
    if(item.Entity_Type){
      NewObj.Entity_Type = item.Entity_Type
    }
  }

  if(item.Created_By){
    NewObj.Created_By = item.Created_By
    NewObj.Created_Byid = item.Created_Byid          
    //NewObj.Created_On = item.Created_On
  }
  else{
    NewObj.Created_By = NewObj.Modified_By
    NewObj.Created_Byid = NewObj.Modified_Byid          
    //NewObj.Created_On = NewObj.Modified_On
  }
  NewObj.Created_On = NewObj.Modified_On
    // let subcolheadersarray = this.SubCollectionItemDialog+'headers'
    let headersarray = this.MasterTableObj.Headers.filter(header => {return header.value !== 'action' && header.value !== 'LookupObj'})
    
    headersarray.map(header => {
      let itemheaderobj = item.Fields.find(obj => obj.value == header.value)
      let itemheadervalue = itemheaderobj.Response
      if(itemheadervalue){
       NewObj[header.propvalue] = itemheadervalue 
      }
    })
    headersarray.map(header => {
      if(header.Type === 'Calculated Field'){
        NewObj[header.propvalue] = this.PerformCalulation(NewObj,header)
      }      

    })
      if(this.SystemConfigorRA && NewObj.Security_Structure){
    NewObj.datasetsecuritystructure = NewObj.Security_Structure.Name
    // if(NewObj.datasetsecuritystructure === 'Custom Roles'){
    //   let roles = ['CustomCreateRoles',]
    //   NewObj.CustomCreateRoles = []
    //   NewObj.CustomGetRoles = []
    //   NewObj.CustomListRoles = []
    //   NewObj.CustomUpdateRoles = []
    //   NewObj.CustomDeleteRoles = []
    // }
  }
    if(item.Entity_Type){
      if(item.Entity_Type.Name === 'Classified Listing' && this.MasterTableObj.EntityBuilder && !this.SystemisRA){
        let clsfdentity = {
          SingleName: NewObj.SingleName,
          PublishType: 'Public',
          ClassifiedTabs: []
        }
        db.collection('SystemConfig').doc(process.env.VUE_APP_RA_SYSTEM_ID).collection('classifiedentities').doc(NewObj.DisplayName.split(' ').join('_')).set(clsfdentity)
      }
    }
    if(NewObj.Primary_Field_Name){
      NewObj.Primary_Field_Name = NewObj.Primary_Field_Name.split(' ').join('_')
    }
    if(this.SystemConfigorRA && item.Entity_Type && NewObj.Primary_Field_Name || this.$route.meta && this.$route.meta.RecordisSystem && item.Entity_Type && NewObj.Primary_Field_Name){
      primaryfieldname = NewObj.Primary_Field_Name.split(' ').join('_').split('(').join('').split(')').join('').split('/').join('')
      primaryfielddisplay = NewObj.Primary_Field_Name
      let recordprimaryfield = {Name: primaryfieldname, DisplayName: primaryfielddisplay, Type: 'Single Line Text',Primary: true, Created: true,IsSearch: true,IsMandatory: true,IsHeader: true,HeaderIndex: 0}
      NewObj.RecordPrimaryField = recordprimaryfield
    }
     
     console.log(NewObj)
    let newdocid = ''
    let ref = this.TableCollectionRef.doc()
    if(this.MasterTableObj && this.MasterTableObj.ConfigValueasDocID && this.MasterTableObj.DocIDProp){
      newdocid = NewObj[this.MasterTableObj.DocIDProp].split(' ').join('_').split("'").join('').split('"').join('').split('/').join('')
    }
    else if(this.MasterTableObj && this.MasterTableObj.Lookup_Once_Only){
      newdocid = NewObj.LookupObj.id
    }
    else{
      newdocid = ref.id
    }
    if(this.NeedsRASync){
      this.ProcessNewSubColEntry(this.RASystemOpsDB,newdocid,NewObj,item,primaryfieldname,primaryfielddisplay,headersarray)
      this.ProcessNewSubColEntry(this.TableCollectionRef,newdocid,NewObj,item,primaryfieldname,primaryfielddisplay,headersarray)
    }
    else{
      this.ProcessNewSubColEntry(this.TableCollectionRef,newdocid,NewObj,item,primaryfieldname,primaryfielddisplay,headersarray)
    }
  
  },
  ProcessNewSubColEntry(CollectionRef,newdocid,NewObj,item,primaryfieldname,primaryfielddisplay,headersarray){
    let vm = this
    if(item.Entity_Type && item.Entity_Type.Name === 'Store Product' && !this.$route.meta.SystemBuilder){
      NewObj.Purchase_Requisitions_Progressed = []
      NewObj.Purchase_Requisitions_Regressed = []
      NewObj.Purchase_Orders_Progressed = []
      NewObj.Purchase_Orders_Regressed = []      
      NewObj.Goods_Receiving_Vouchers_Progressed = []
      NewObj.Goods_Receiving_Vouchers_Regressed = []
      NewObj.Picking_Slips_Progressed = []
      NewObj.Picking_Slips_Regressed = []
      NewObj.Packing_Lists_Progressed = []
      NewObj.Packing_Lists_Regressed = []
      NewObj.Dispatch_Instructions_Progressed = []
      NewObj.Dispatch_Instructions_Regressed = []
      NewObj.Invoiced_Qty = 0
      NewObj.Credited_Qty = 0
      if(item.LookupObj && item.LookupObj.list){
        let lookupbuild = this.SystemEntities.find(obj => obj.id === item.LookupObj.list)
      let buildapp = this.AppsDataBase.find(obj => obj.id === lookupbuild.Primary_Appid) 
      let articletablefield = lookupbuild.AllFields.find(obj => obj.id === 'Inventory_Article')
      if(articletablefield){
        item.ArticleTableField = articletablefield
      }
      if(buildapp && !buildapp.Plugins.find(obj => obj.id === 'Warehouse_Outbound')){
        item.Bypass_Picking_and_Packing = true
      }
      // if(typeof subcol.Available_Stock_Only !== 'undefined'){
      //   item.Available_Stock_Only = subcol.Available_Stock_Only
      // }
      }
      
    }
    CollectionRef.doc(newdocid).set(NewObj).then(doc => {
      //console.log('item',item,this.RelatedObj,this.CurrentEntity,NewObj)
    if(item.Entity_Type && item.Entity_Type.Name === 'Store Product' && !this.$route.meta.SystemBuilder){
      
      if(!this.CurrentEntity.Precede_with_Quote || this.RelatedObj.Order_Progress && this.RelatedObj.Order_Progress.Name === 'Approved'){
      //now in fact if CurrentEntity is "Precede with Quote" then do not consume. !E-COMMERCE PLUGIN
      console.log(this.CurrentEntity.Primary_Field_Name,this.MasterTableObj)
      let Inventory_ArticleField = ''
      let payload = {
      Data: {
      LookupObj: {
        [this.CurrentEntity.Primary_Field_Name]: this.RelatedObj[this.CurrentEntity.Primary_Field_Name],
        id: this.RelatedObj.id,
        list: this.CurrentEntity.DisplayName,
        single: this.CurrentEntity.SingleName,
        identifier: this.CurrentEntity.Primary_Field_Name,
        SubColid: this.MasterTableObj.id,
        SubColRecordid: newdocid
      },
      StockObj: NewObj.LookupObj,
      Reference_Number: newdocid,
      Qty: NewObj.Qty,
      Created_By: NewObj.Created_By,
      Created_Byid: NewObj.Created_Byid,
      Modified_By: NewObj.Modified_By,
      Modified_Byid: NewObj.Modified_Byid,
      Progress: {ID: 1000005,Name: 'Completed'},
      Warehouseid: item.Warehouseid
      },
      //Collection: NewObj.LookupObj.list.split('_').join('').toLowerCase(),
      // id: NewObj.LookupObj.id
      }
      payload.Data.StockObj.UpdateStore = item.AddedtoStore
      console.log(Inventory_ArticleField,item.Business_Unitid,item.Inventory_Articleid)
      let storprodent = this.SystemEntities.find(obj => obj.id === NewObj.LookupObj.list)
      if(storprodent){
        Inventory_ArticleField = storprodent.AllFields.find(obj => obj.id === 'Inventory_Article')
        if(Inventory_ArticleField && item.Warehouseid && item.Inventory_Articleid){
          payload.Data.StockObj.StoreCollection = Inventory_ArticleField.RelatedBuildID
          payload.Data.StockObj.WarehouseDoc = item.Inventory_Articleid
          payload.Data.StockObj.WarehouseID = item.Warehouseid
        }
      }      
      if(item.Bypass_Picking_and_Packing){
        payload.Data.Bypass_Picking_and_Packing = item.Bypass_Picking_and_Packing
        payload.Data.Progress = {ID: 1000005,Name: 'Completed'}
        payload.Collection = 'DispatchInstructions'
      }
      else{
        payload.Collection = 'PickingSlips'
        payload.Data.Progress = {ID: 1000001,Name: 'Issued'}
      }
      console.log(payload)
      const functions = firebase.functions();
      const ConsumeStockQty = functions.httpsCallable('ConsumeStockQty');
      const ConsumeBulkStockQty = functions.httpsCallable('ConsumeBulkStockQty');
      // if(item.Bypass_Picking_and_Packing){
      //  ConsumeStockQty(payload).then(result => {
      //   vm.DeactivateSubColLookupDialog()
      // console.log(result)
      // }) 
      // }
      // else{
        let arraydataentry = Object.assign({},payload)
        arraydataentry.Data.Warehouse = {
          LookupObj: {},
          Available: item.Available,
          // Price: arraydataentry.Data.Price,
          // Purchase_Leadtime: item.Purchase_Leadtime,
          // Supply_LeadTime: item.Supply_LeadTime,
          id: item.Warehouseid
        }
        let ouboundrecord = JSON.parse(JSON.stringify(arraydataentry))
        let inboundrecord = JSON.parse(JSON.stringify(arraydataentry))
        let inboundqty = NewObj.Qty-item.Available
        let whoncheck = vm.WarehouseEntries.find(obj => obj.id === item.Warehouseid)
        if(!whoncheck){
          vm.WarehouseEntries.push({id: item.Warehouseid,InboundStockRecords: [],OutboundStockRecords: []})
        }
        let finalwhcheck = vm.WarehouseEntries.find(obj => obj.id === item.Warehouseid)
        //console.log('inboundqty',inboundqty)
        if(inboundqty <= 0){
          finalwhcheck.OutboundStockRecords.push(ouboundrecord)
        }
        else{      
          if(item.Available > 0){
            ouboundrecord.Data.Qty = item.Available
            finalwhcheck.OutboundStockRecords.push(ouboundrecord)
          }
          inboundrecord.Data.Qty = inboundqty
          finalwhcheck.InboundStockRecords.push(inboundrecord)
        }
        
        
        vm.ConsumeBulkQuantityArrayCounter = vm.ConsumeBulkQuantityArrayCounter-1+2
        if(vm.ConsumeBulkQuantityArrayCounter === vm.SelectedSubcollectionArray.length){
          vm.WarehouseEntries.map(wh => {
           console.log('wh.OutboundStockRecords',wh.OutboundStockRecords)
           console.log('wh.InboundStockRecords',wh.InboundStockRecords)
          let bulkoutboundpayload = {
            Collection: payload.Collection,
            Data: wh.OutboundStockRecords,
            ParentObj: {LookupObj: payload.Data.LookupObj,
            Progress: payload.Data.Progress,
            Created_By: {id: vm.userLoggedIn.id, Name: vm.userLoggedIn.Name, Surname: vm.userLoggedIn.Surname, Full_Name: vm.userLoggedIn.Full_Name},Created_Byid: vm.userLoggedIn.id,
            Modified_By: {id: vm.userLoggedIn.id, Name: vm.userLoggedIn.Name, Surname: vm.userLoggedIn.Surname, Full_Name: vm.userLoggedIn.Full_Name},Modified_Byid: vm.userLoggedIn.id},
            Warehouse: wh.id
          }
          if(payload.Data.Bypass_Picking_and_Packing){
            bulkoutboundpayload.Bypass_Picking_and_Packing = payload.Data.Bypass_Picking_and_Packing
          }
          if(wh.OutboundStockRecords.length > 0){
            console.log('doing OutboundStockRecords',bulkoutboundpayload)
            ConsumeBulkStockQty(bulkoutboundpayload).then(result => {
              let warehouse = vm.WarehousesArray.find(obj => obj.id === item.Warehouseid)
              if(warehouse && warehouse.TransactionNotificationParty){
                vm.SendTransactionNotificationEmail(warehouse.TransactionNotificationParty,headersarray,NewObj)
              }
              else{
                vm.DeactivateSubColLookupDialog()
              }              
            console.log(result)
            }) 
          }
          if(wh.InboundStockRecords.length > 0){
            console.log('doing InboundStockRecords')
            let bulkinboundpayload = Object.assign({},bulkoutboundpayload)
            bulkinboundpayload.Data = wh.InboundStockRecords.map(prlinteitem => {
              prlinteitem.Data.Outstanding_Qty = prlinteitem.Data.Qty
              prlinteitem.Data.On_Order = 0
              prlinteitem.Data.Outstanding_Order = Number(prlinteitem.Data.Qty)
              prlinteitem.Data.Open_Allocations = true
              return prlinteitem
            })
            bulkinboundpayload.Collection = 'PurchaseRequisitions'
            ConsumeBulkStockQty(bulkinboundpayload).then(result => {
              vm.DeactivateSubColLookupDialog()
            console.log(result)
            }) 
          } 
          })
          
          
        }
      // }
    }
      
    }
    // this.TableCollectionRef.add(NewObj).then(doc => {
      console.log("done obj")
      if(item.SubCollections){
      item.SubCollections.map(subcol => {
        subcol.SubColData.map(subcoldata => {
          //console.log(NewObj.Entity_Type,subcol,subcoldata.id,item.RecordPrimaryField)
          if(this.SystemConfigorRA && NewObj.Entity_Type && NewObj.Primary_Field_Name && subcol.SubColName === 'AllFields' && subcoldata.id === item.RecordPrimaryField.Name){
            subcoldata.id = primaryfieldname
            subcoldata.Name = primaryfieldname
            subcoldata.DisplayName = primaryfielddisplay
          }
          CollectionRef.doc(newdocid).collection(subcol.SubColName).doc(subcoldata.id).set(subcoldata)
        })
        if(this.SystemConfigorRA && NewObj.Entity_Type && NewObj.Primary_Field_Name && NewObj.Security_Structure && NewObj.Security_Structure.Name === 'Business Unit'){
          let bufield = {Name: 'Business_Unit', DisplayName: 'Business Unit', Type: 'Lookup',LookupFieldName: 'Name',ReadOnly: true,RelatedBuildID: 'Business_Units',RelatedLocation: 'Business Unit', RelatedRecord: 'Business Units',Primary: false, Created: true, IsMandatory: true, IsHeader: true,HeaderIndex: 1, UsedonCompForm: true, UsedonForm: true,  SystemRequired: true}
          CollectionRef.doc(newdocid).collection('AllFields').doc('Business_Unit').set(bufield)
          let ownerfield = {Name: 'Owner', DisplayName: 'Owner', Type: 'Lookup',LookupFieldName: 'Full_Name',RelatedBuildID: 'Users',RelatedLocation: 'User', RelatedRecord: 'Users',Primary: false, Created: true, IsMandatory: true, IsHeader: true,HeaderIndex: 2, UsedonCompForm: true, UsedonForm: true,  SystemRequired: true}
          CollectionRef.doc(newdocid).collection('AllFields').doc('Owner').set(ownerfield)
        }
        else if(this.SystemConfigorRA && NewObj.Entity_Type && NewObj.Primary_Field_Name && NewObj.Security_Structure && NewObj.Security_Structure.Name === 'Warehouse'){
          let bufield = {Name: 'Business_Unit', DisplayName: 'Business Unit', Type: 'Lookup',LookupFieldName: 'Name',ReadOnly: true,RelatedBuildID: 'Business_Units',RelatedLocation: 'Business Unit', RelatedRecord: 'Business Units',Primary: false, Created: true, IsMandatory: true, IsHeader: true,HeaderIndex: 1, UsedonCompForm: true, UsedonForm: true,  SystemRequired: true}
          CollectionRef.doc(newdocid).collection('AllFields').doc('Business_Unit').set(bufield)
          //we propogate WH field as and when needed, no need to add as field or elese it will duplicate
          // let whfield = {Name: 'Warehouse', DisplayName: 'Warehouse', Type: 'Lookup',LookupFieldName: 'Name',RelatedBuildID: 'Warehouses',RelatedLocation: 'Warehouse', RelatedRecord: 'Warehouses',Primary: false, Created: true, IsMandatory: true, IsHeader: true,HeaderIndex: 2, UsedonCompForm: true, UsedonForm: true,  SystemRequired: true}
          // CollectionRef.doc(newdocid).collection('AllFields').doc('Warehouse').set(whfield)
        }              
      })
      //vm.DeactivateSubColLookupDialog()
    }
    else{
      //vm.DeactivateSubColLookupDialog()
    }
    })
  },
  SendTransactionNotificationEmail(emailtousers,headersarray,item){
      let emailto = ''
      let emailtolength = emailtousers.length
      emailtousers.map((user,userindex) => {
        let userobj = this.UsersArray.find(obj => obj.id === user)
        if(userobj && userobj.Email){
          emailto = emailto+userobj.Email
          if(userindex-1+2 < emailtolength){
            emailto = emailto+', '
          }
        }
          if(userindex-1+2 === emailtolength){
            this.ProceedTransactionEmailNotification(emailto,headersarray,item)
          }
      })

    },
    ProceedTransactionEmailNotification(emailto,FormFields,newrecord){
      console.log(FormFields,newrecord)
      let vm = this
      let emailsubject = `<h2>`+this.System.Name+` - `+this.CurrentEntity.SingleName+` Transaction - `+this.RelatedObj[this.RelatedObjidentifier]+` </h2>`
      let EmailBody = `<html>
<body>
<head></head>
`+emailsubject+
`<p>This is an automated notification about the subjected Transaction</p>
<table>`
             let length = FormFields.length
            FormFields.map((field,i) => {
                EmailBody = EmailBody+`<tr>
<th align="left">`+field.text+`:</th>
<td align="left">`+newrecord[field.propvalue]+`</td>
</tr>`
    if(i-1+2 === length){
        EmailBody = EmailBody+`
    </table>`
                 } 
            })
            let link = this.System.ClientAppURL+'/'+this.TableEntryPath+'/'+this.RelatedObj.id
          EmailBody = EmailBody+`<p>To view the details more details, click <a href="`+link+`">here</a></p>` 
          EmailBody = EmailBody+`<i>This update was made by `+this.userLoggedIn.Full_Name+` on `+new Date()+`</i>`  
          EmailBody = EmailBody+`
</body>
</html>`  
            let EmailObject = {
                EmailFrom: 'notification@rapidapps.co.za',
                EmailTo: emailto,
                EmailSubject: emailsubject,
                EmailBody: EmailBody
            }
            //console.log(EmailObject)
            const functions = firebase.functions();
            const SendEmailNow = functions.httpsCallable('sendEmailNow');
            SendEmailNow(EmailObject).then(result => {
              //console.log(result)
               vm.DeactivateSubColLookupDialog()
            })  
    },
      LinkSubCollectionArray(array,lookupobj){
        console.log(array,lookupobj)
        this.OutboundStockRecords = []
        this.InboundStockRecords = []
        this.WarehouseEntries = []
        this.SelectedSubcollectionArray = array.map(item => {
            //console.log(item)
            item.Fields = JSON.parse(JSON.stringify(lookupobj.ArrayHeaders))
            //here we actually perform the needed mappings..
            //<span v-if="build.LinkedFields[header.value]">
            //so if lookupobj.LinkedFields
            if(lookupobj.LinkedFields){
              item.Fields.map(header => {
                let MapObj = lookupobj.LinkedFields[header.value] 
                //console.log(MapObj)   
                if(MapObj && MapObj.value !== 'Available' || MapObj && MapObj.value === 'Available' && !item.IsBOMQty){       
                  header.Response = item[MapObj.value]                    
                }
                else if(MapObj && MapObj.value === 'Available' && item.IsBOMQty){
                  header.Response = item.Qty
                }
              })              
            }
            
              //
              item.LookupObj = {
                [lookupobj.identifier]: item[lookupobj.identifier],
                id: item.id,
                list: lookupobj.ArrayName,
                single: lookupobj.single
              } 
              item.LinkedFromIdentifier = lookupobj.identifier //build.recordprimaryfield.Name
              
              if(lookupobj.Bypass_Picking_and_Packing){
                item.Bypass_Picking_and_Packing = lookupobj.Bypass_Picking_and_Packing
              }              
              if(lookupobj.Entity_Type){
                item.Entity_Type = lookupobj.Entity_Type
              }
              if(lookupobj.ArticleTableField){
                item.ArticleTableField = lookupobj.ArticleTableField
              }
              console.log('LinkSubCollectionArray item',item)
            return item
          })
          this.CancelSCAddParentDialog()
          //console.log('this is Fields for AllFields')
          this.SubColLookupDialog = true
          this.SubCollectionItemDialog = lookupobj.ParentType
        // }
        console.log(this.SelectedSubcollectionArray)
      },
        DeactivateSubColLookupDialog(){
          this.$emit('DeactivateSubColLookupDialog')
        },
        SubColdialogActivate(){
        this.SubCollectionLookupArrays = []
        console.log(this.RelatedObj,this.tab,this.TableCollectionRef,this.MasterTableObj,this.SubCollectionLookupArrays)
        // this.GetLookupRecords
       
        let ArrayHeaders = this.MasterTableObj.Headers
        if(this.MasterTableObj && this.MasterTableObj.Type === 'Lookup' && this.MasterTableObj.LookupBuilds){
          let LookupArrays = []
          let lookupbuildslength = this.MasterTableObj.LookupBuilds.length
          this.ObtainLookupBuildsData(LookupArrays,ArrayHeaders,lookupbuildslength).then(result => {
            this.SubCollectionLookupArrays = result
            this.SCViewExistingLookupDialog = true
          })         
          
        }
        else{
          this.dialog = true
        }
        
        
        
    },
    PrepareLookupBuildData(LookupArrays,ArrayHeaders,build){
      console.log(LookupArrays,ArrayHeaders,build)
        let vm = this
        let storearray = vm.$store.state[build.id.split('_').join('')+'Array']   
        return new Promise(function(resolve, reject) {	     
        let lookupobj = 
                {
                ArrayName:  build.id,//yeah I guess array name
                ArrayData: storearray.map(subcolrecord => {
                  console.log('subcolrecord',subcolrecord,build)
                  if(build.LinkedFields){
                    ArrayHeaders.map(header => {
                      let MapObj = build.LinkedFields[header.value]    
                      if(MapObj){       
                        subcolrecord[header.value] = subcolrecord[MapObj.value]                    
                      }
                    })              
                  }
                  if(build.Entity_Type && build.Entity_Type.Name === 'Store Product' && build.Available_Stock_Only){
                    if(!subcolrecord.Available || subcolrecord.Available && subcolrecord.Available <= 0){
                      subcolrecord.disabled = true
                    }
                  }
                  if(build.SubCollections){         
                     if(vm.MasterTableObj.EntityBuilder && build.id === 'Entities'){
                       
                       //simply push the required
                       let gallreyon = build.SubCollections.find(obj => obj === 'Galleries')
                       if(!gallreyon){
                         build.SubCollections.push('Galleries')
                       }
                      let docregon = build.SubCollections.find(obj => obj === 'Document_Registers')
                       if(!docregon){
                         build.SubCollections.push('Document_Registers')
                       }
                      }            
                      let childsubcollength = build.SubCollections.length
                      //alert('hey man '+childsubcollength)
                      build.SubCollections.map((subcolchild,subcolchildindex) => {
                        
                        //console.log('build.SubCollections',build.SubCollections,build.id,subcolentry,subcolchild,db.collection(build.id.split('_').join('').toLowerCase()).doc(subcolentry.id).collection('AllFields'))
                        if(vm.MasterTableObj.EntityBuilder && build.id === 'Entities' && !vm.SystemisRA){
                         vm.GetSubColSubCol(build.id,subcolrecord,subcolchild,vm.RADB.collection(build.id.split('_').join('').toLowerCase()).doc(subcolrecord.id).collection(subcolchild)).then(result => {
                            if(subcolchildindex-1+2 === childsubcollength){
                              console.log('result',result)
                              subcolrecord = result
                            }
                          }) 
                        }
                        else{
                          vm.GetSubColSubCol(build.id,subcolrecord,subcolchild,db.collection(build.id.split('_').join('').toLowerCase()).doc(subcolrecord.id).collection(subcolchild)).then(result => {
                            if(subcolchildindex-1+2 === childsubcollength){
                              console.log('result',result)
                              subcolrecord = result
                            }
                          })
                        }
                      
                    })
                   }
                  //  else if(buildindex-1+2 === lookupbuildslength){
                  //    return subcolrecord
                  //  }
                   return subcolrecord
                }),//subcoldata
                ArrayHeaders: ArrayHeaders,//subcolheaders
                identifier: build.identifier,
                ParentType: vm.MasterTableObj.id,
                single: build.single
                }
                if(build.Entity_Type){
                      lookupobj.Entity_Type = build.Entity_Type
                  }
                  
                  if(typeof build.Bypass_Picking_and_Packing !== 'undefined'){
                    lookupobj.Bypass_Picking_and_Packing = build.Bypass_Picking_and_Packing
                  }
                  if(build.ArticleTableField){
                    lookupobj.ArticleTableField = build.ArticleTableField
                  }
                if(build.LinkedFields){
                  lookupobj.LinkedFields = build.LinkedFields
                }
                if(build.TableHeaders){
                    lookupobj.TableHeaders = build.TableHeaders
                  }
                  if(build.Headers){
                    lookupobj.TableHeaders = build.Headers
                  }
                  if(build.Security_Structure && build.Security_Structure.Name === 'Business Unit'){
                    lookupobj.HasBusinessUnit = true
                  }
                  if(build.Entity_Type && build.Entity_Type.Name === 'Store Product'){
                    lookupobj.HasWarehouse = true
                  }
                  console.log('lookupobj',lookupobj)
              resolve(lookupobj)
        })
      },
      GetSubColSubCol(entityid,record,subcolname,subcolquery,headers){
      console.log('GetSubColSubCol',entityid,record,subcolname,subcolquery,headers)
      let subcol = {
        SubColName: subcolname,
        SubColData: [],
        
      }
      if(entityid){
        subcol.PrimaryEntity = entityid
      }
      if(headers){
        subcol.Headers = headers
      }
      let vm = this									
       return new Promise(function(resolve, reject) {		
         subcolquery.onSnapshot(res=> {
           let length = res.docs.length
           if(length === 0){
             resolve(record)
           }
           else{
             if(!record.SubCollections){
               record.SubCollections = []
             }
            const changes = res.docChanges();
            changes.forEach(change => {
              if (change.type === 'added') {
                subcol.SubColData.push({
                  ...change.doc.data(),
                  id: change.doc.id
                })
                if(subcol.SubColData.length === length){
                  record.SubCollections.push(subcol)
                  resolve(record)
                }
              }
            })
           }
           
         })
       })
    },
    ObtainLookupBuildsData(LookupArrays,ArrayHeaders,lookupbuildslength){
        let vm = this		
        let array = []							
       return new Promise(function(resolve, reject) {		
        vm.MasterTableObj.LookupBuilds.map((build,buildindex) => {
            let storearray = vm.$store.state[build.id.split('_').join('')+'Array']
            let storequery = vm.$store.state[build.id.split('_').join('')+'Query']
            if(!storearray || storearray && storearray.length === 0 && storequery){
              let storepayload = {
                query: storequery,
                arrayname: build.id.split('_').join('')+'Array'
              }
              console.log(storepayload,vm.$store.state,storearray)
              vm.$store.dispatch('GetCollectionArray',storepayload).then(result => {
                console.log('oooh yaya a result',result)
                storearray = vm.$store.state[build.id.split('_').join('')+'Array']
                //do something
                vm.PrepareLookupBuildData(LookupArrays,ArrayHeaders,build).then(lookupobj => {
                  LookupArrays.push(lookupobj)
                  array = array.concat(LookupArrays)
                  if(LookupArrays.length === lookupbuildslength){
                    resolve(array)
                  }
                })
              })
              
            }
            else{
              //do same thing
              vm.PrepareLookupBuildData(LookupArrays,ArrayHeaders,build).then(lookupobj => {
                  LookupArrays.push(lookupobj)
                  array = array.concat(LookupArrays)
                  if(LookupArrays.length === lookupbuildslength){
                    resolve(array)
                  }
                })
            }
            console.log('MasterTableObj.LookupBuilds build',build,vm.MasterTableObj)
              
              
              // if(LookupArrays.length === lookupbuildslength){
              //   resolve(array)
              // }
          })
       })
      },
    }
}
</script>

<style>

</style>


